<template>
  <v-sheet rounded="lg" class="surface">
    <v-alert
        dense
        type="info"
        class="text-left"
        width="100%" @click="showInformation= !showInformation">
      <div>Priority levels
      </div>
    </v-alert>
    <v-sheet rounded="lg" class="background pa-4 my-2" v-if="showInformation">
      <div class="text-h6">Definitions</div>
      <v-divider class="my-2"/>
      <div class="text-body-1" v-for="(priority,index) in priorityList" :key="index">
        {{ getPriorityInfo(priority).definition }}
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "AnnouncementDefinitions",
  props: {
    loading: {
      type: Boolean
    },
    priorityList: {
      type: Array
    }
  },
  data(){
    return{
      showInformation:false
  }
},
  computed: {
    ...mapState("priorityLevels", ["priorityLevels"]),
  },
  methods: {
    getPriorityInfo(priorityLevel) {
      return this.priorityLevels.find(i => {
        return i.value == priorityLevel
      })
    },
  }
}
</script>

<style scoped>

</style>