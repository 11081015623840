<template>
  <v-sheet rounded="lg" class="surface">
    <v-alert
        dense
        type="info"
        class="text-left"
        width="100%" @click="showDataTable= !showDataTable">
      <div>Status symbology</div>
    </v-alert>
      <v-data-table :items="items" :headers="headers" hide-default-footer v-show="showDataTable" :sort-by="['furtherActionRequired']" :sort-desc="[true]"
                    multi-sort>
        <template v-slot:item.icon="{ item }">
          <v-icon :color="item.color">{{ item.icon }}</v-icon>
        </template>
        <template v-slot:item.appliesToRoutine="{ item }">
          <v-icon>{{ item.appliesToRoutine ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </template>
        <template v-slot:item.appliesToPriority="{ item }">
          <v-icon>{{ item.appliesToPriority ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </template>
        <template v-slot:item.appliesToCritical="{ item }">
          <v-icon>{{ item.appliesToCritical ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </template>
        <template v-slot:item.furtherActionRequired="{ item }">
          <v-icon>{{ item.furtherActionRequired ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
        </template>
      </v-data-table>
  </v-sheet>
</template>

<script>
export default {
  name: "AnnouncementStatusDefinitions",
  data() {
    return {
      showDataTable:false,
      headers: [
        {text: 'icon', value: 'icon'},
        {text: 'what does it mean?', value: 'definition'},
        {text: 'any further action?', value: 'furtherActionRequired'},
        {text: 'What do i need to do?', value: 'requiredAction'},
      ],
      items: [
        {
          icon: 'mdi-email',
          color: 'red',
          name: 'Unread',
          appliesToRoutine: false,
          appliesToPriority: true,
          appliesToCritical: true,
          furtherActionRequired:true,
          requiredAction:'Acknowledge that you have read it using the button on the announcement.This must carried out prior to further flight operations.',
          definition: 'The announcement as been posted but you have yet to acknowledged that you have read it. This must be read prior to club/group or further flight operations.'
        },
        {
          icon: 'mdi-email',
          color: 'orange',
          name: 'Unread',
          appliesToRoutine: true,
          appliesToPriority: false,
          appliesToCritical: false,
          furtherActionRequired:true,
          requiredAction:'Acknowledge that you have read it using the button on the announcement. You can do this at any convenient time.',
          definition: 'The announcement as been posted but you have yet to acknowledged that you have read it.'
        },
        {
          icon: 'mdi-email-open',
          color: 'green',
          appliesToRoutine: true,
          appliesToPriority: false,
          appliesToCritical: false,
          furtherActionRequired:false,
          requiredAction:'',
          name: 'Opened',
          definition: 'The announcement as been posted and you have acknowledged that you have read it'
        },
        {
          icon: 'mdi-email-open',
          color: 'red',
          appliesToRoutine: false,
          appliesToPriority: true,
          appliesToCritical: true,
          furtherActionRequired:true,
          requiredAction:'Read and understand the instructions included in the message or referenced documentation in the message. When you are ready to sign to say that you understood and will comply, press the \'electronically sign\' button on the announcement. This must carried out prior to further flight operations.',
          name: 'Opened',
          definition: 'The announcement as been posted and you have acknowledged that you have read it.'
        },
        {
          icon: 'mdi-signature-freehand',
          color: 'green',
          appliesToRoutine: false,
          appliesToPriority: true,
          appliesToCritical: true,
          furtherActionRequired:false,
          requiredAction:'',
          name: 'Electronically Signed',
          definition: 'The announcement is a Critical or Priority message and you have acknowledged that you have read and electronically signed that have understood and will comply with either the instructions in the message or referenced documentation in the message.'
        }

      ]
    }
  },

}
</script>

<style scoped>

</style>