import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthor)?_c(VMenu,{attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onTooltip }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},{ ...onMenu, ...onTooltip }),[_c(VIcon,{attrs:{"size":"25"}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v("Options")])])]}}],null,false,1889867633)},[_c(VSheet,{attrs:{"rounded":""}},[_c(VList,{attrs:{"dense":"","tile":false}},[_c(VListItemGroup,[_vm._l((_vm.scopedMenuItems),function(item,i){return _c(VListItem,{key:i,attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.emitAction(item.action)}}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)}),_c(VDivider),_c(VListItem,{on:{"click":function($event){return _vm.emitAction('deletePost')}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-delete-forever")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Delete post")])],1)],1)],2)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }