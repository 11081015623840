<script>
import { mapActions, mapState } from "vuex";
import { getGroupName } from "@/util/helpers";
import MemberDirectory from "@/components/shared/MemberDirectory";
import FileExplorerBase from "@/components/fileManager/FileManagerBase.vue";
import GroupFeed from "@/components/shared/GroupFeed.vue";

export default ({
  name: "GroupsPage",
  components: {
    MemberDirectory,
    FileExplorerBase,
    GroupFeed
    // other components if any
  },
  data() {
    return {
      activeGroup: null,
      activeTab: "contacts"
    };
  },
  computed: {
    ...mapState("app", ["loggedInUser", "loading"]),
    ...mapState("person", ["persons"]),
    ...mapState("user", ["groupMembership"]),
    ...mapState("group", ["groups"]),

    scopedPersons() {
      return this.activeGroup ? this.persons.filter(p => p.groupMembership.some(g => g._id === this.activeGroup)) : [];
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("user", ["setFlyingStatus"]),
    getGroupName(id, style) {
      return getGroupName(this.groups, id, style);
    },

    async changeGroup(group) {
      this.activeGroup = group._id;
      this.setPersons(this.activeGroup);
    }
  },
  async mounted() {
    if (this.groupMembership) {
      await this.changeGroup(this.groupMembership[0]);
    }
  }
});
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-sheet rounded="lg" class="surface">
          <h1 class="text-center">Groups</h1>
          <v-divider class="my-2" />
          <v-list color="transparent">
            <v-list-item-group v-model="activeGroup">
              <v-list-item
                v-for="(n) in groupMembership"
                :key="n._id" @change="changeGroup(n)" @input="changeGroup(n)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getGroupName(n._id, "firstUse") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet rounded="lg">
          <v-tabs
            :centered="$vuetify.breakpoint.mdAndUp"
            v-model="activeTab"
            :vertical="$vuetify.breakpoint.smAndDown"
            grow
          >
            <v-tab value="feed">
              <v-icon class="pr-2">mdi-message</v-icon>
              <span>Feed</span>
            </v-tab>
            <v-tab value="contacts">
              <v-icon class="pr-2">mdi-account-multiple</v-icon>
              <span>Members</span>
            </v-tab>
            <v-tab value="file_manager">
              <v-icon  class="pr-2">mdi-file-tree</v-icon>
              <span>Files</span>
            </v-tab>
          </v-tabs>
          <v-divider class="my-2" />
          <v-tabs-items v-model="activeTab">
            <v-tab-item
              href="#feed"
            >

              <group-feed :active-group="activeGroup" />
            </v-tab-item>
            <v-tab-item
              href="#contacts"
            >

              <member-directory :items="scopedPersons" />
            </v-tab-item>
            <v-tab-item
              href="#file_manager"
            >
              <FileExplorerBase :group="activeGroup.toString()" v-if="activeGroup" />
            </v-tab-item>
          </v-tabs-items>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>


<style scoped>

</style>