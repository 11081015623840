<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-sheet rounded="lg" class="surface">
          <h1 class="text-center">Groups</h1>
          <v-divider class="my-2"/>
          <v-list color="transparent">
            <v-list-item-group v-model="activeGroup">
              <v-list-item
                  v-for="(n) in groupMembership"
                  :key="n._id" @change="changeGroup(n)" @input="changeGroup(n)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getGroupName(n._id,'firstUse') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" md="9">
        <v-sheet
            min-height="70vh"
            rounded="lg"
            class="surface"
        >
          <v-sheet rounded="lg" class="background pt-2 my-3">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Contacts
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
            <v-divider class="my-2"/>
            <member-directory :items="scopedPersons"/>
          </v-sheet>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex";
import MemberDirectory from "../../components/shared/MemberDirectory";
import {getGroupName} from "@/util/helpers";

export default {
  name: "ContactsPage",
  components: {MemberDirectory},
  data() {
    return {
      activeGroup: null
    }
  },
  computed: {
    ...mapState("app", ["loggedInUser", "loading"]),
    ...mapState("person", ["persons"]),
    ...mapState("user",["groupMembership"]),
    ...mapState("group",["groups"]),

    scopedPersons() {
      return this.activeGroup ? this.persons.filter(p => p.groupMembership.some(g => g._id === this.activeGroup)) : []
    }
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("person", ["setPersons",]),
    ...mapActions("user", ["setFlyingStatus"]),
    getGroupName(id,style){
      return getGroupName(this.groups,id,style)
    },

    async changeGroup(group) {
      this.activeGroup = group._id
      this.setPersons(this.activeGroup)
    },
  },
  mounted() {
    this.changeGroup(this.groupMembership[0])
  }
}
</script>

<style scoped>

</style>