<template>
  <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="isAuthor"
  >
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn
              icon
              v-on="{ ...onMenu, ...onTooltip }">
            <v-icon size="25">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <span>Options</span>
      </v-tooltip>
    </template>

    <v-sheet rounded>
      <v-list
          dense
          :tile="false">
        <v-list-item-group
        >
          <v-list-item
              v-for="(item, i) in scopedMenuItems"
              :key="i" :disabled="item.disabled" @click="emitAction(item.action)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider/>
          <v-list-item @click="emitAction('deletePost')">
            <v-list-item-icon>
              <v-icon>mdi-delete-forever</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Delete post</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: "PostActionMenu",
  props: {
    postId: {
      type: String,
      required: true
    },
    isAuthor: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    authorMenuItems: [
      {
        text: 'Edit post',
        icon: 'mdi-pencil',
        toolTip: 'Edit post',
        action: 'editPost',
        disabled: false
      },
      // {
      //   text: 'Edit audience',
      //   icon: 'mdi-earth',
      //   toolTip: 'Mark this announcement as Read',
      //   action: 'markAsRead',
      //   disabled: true
      // },
      // {
      //   text: `Make this a 'sticky' post`,
      //   icon: 'mdi-sticker',
      //   toolTip: 'Mark this announcement as Read',
      //   action: 'markAsRead',
      //   disabled: true
      // },
      // {
      //   text: `Turn off/on Comments`,
      //   icon: 'mdi-comment-outline',
      //   toolTip: 'Mark this announcement as Read',
      //   action: 'markAsRead',
      //   disabled: true
      // }
    ],
  }),
  computed: {
    scopedMenuItems() {
      return this.isAuthor ? this.authorMenuItems : []
    }
  },
  methods: {
    emitAction(action) {
      this.$emit(action)
    }
  }
}
</script>

<style scoped>

</style>