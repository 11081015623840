import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4","md":"3"}},[_c(VSheet,{staticClass:"surface",attrs:{"rounded":"lg"}},[_c('h1',{staticClass:"text-center"},[_vm._v("Groups")]),_c(VDivider,{staticClass:"my-2"}),_c(VList,{attrs:{"color":"transparent"}},[_c(VListItemGroup,{model:{value:(_vm.activeGroup),callback:function ($$v) {_vm.activeGroup=$$v},expression:"activeGroup"}},_vm._l((_vm.groupMembership),function(n){return _c(VListItem,{key:n._id,on:{"change":function($event){return _vm.changeGroup(n)},"input":function($event){return _vm.changeGroup(n)}}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-domain")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.getGroupName(n._id,'firstUse'))+" ")])],1)],1)}),1)],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"8","md":"9"}},[_c(VSheet,{staticClass:"surface",attrs:{"min-height":"70vh","rounded":"lg"}},[_c(VSheet,{staticClass:"background pt-2 my-3",attrs:{"rounded":"lg"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Contacts ")])],1),_c(VListItemAction)],1),_c(VDivider,{staticClass:"my-2"}),_c('member-directory',{attrs:{"items":_vm.scopedPersons}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }