<template>
  <v-sheet class="surface">
      <v-container fluid>
        <v-row dense>
          <v-col v-for="(person, index) in items" :key="index" cols="12" md="4">
            <!-- application card -->
            <member-card
                :id="person._id"
                :name="person.name"
                :image="makeImageSrc(person.avatarImage)"
                :title="person.title"
                :role="person.role"
                certificates=""
                :email="person.email"
                :phone="person.mobile"/>
          </v-col>
        </v-row>
      </v-container>
  </v-sheet>
</template>

<script>
import {mapActions, mapState} from "vuex";
import MemberCard from "@/components/shared/MemberCard";

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: "MemberDirectory",
  components: {MemberCard},
  props: ['items'],
  data: () => ({

    active: [],
    open: [],
    users: [],
    search: null,
    caseSensitive: false,
    canCopy: false
  }),
  async created() {
    this.canCopy = !!navigator.clipboard;
  },
  computed: {
    ...mapState("app", ["loggedInUser"]),
    filter() {
      return this.caseSensitive
          ? (item, search, textKey) => item[textKey].indexOf(search) > -1
          : undefined
    },
    selected() {
      if (!this.active.length) return undefined

      const id = this.active[0]

      return this.items.find(user => user._id === id)
    },
    // items() {
    //   return [
    //     {
    //       name: 'Members',
    //       children: this.persons,
    //     },
    //   ]
    // },
  },

  methods: {
    ...mapActions("app", ["setToast"]),
    ...mapActions("person", ["setPersons"]),
    makeImageSrc(avatarimage) {
      if (avatarimage) {
        return `data:image/${avatarimage.contentType};base64, ${avatarimage.data.toString('base64')}`
      } else {
        return ''
      }
    },
    async copyToClipboard(s) {
      await navigator.clipboard.writeText(s);
      this.setToast({color: 'success', text: 'Copied to clipboard', show: true})
    },
    async fetchUsers(item) {
      // Remove in 6 months and say
      // you've made optimizations! :)
      await pause(1500)
      item.children.push(this.persons)
    },
  },
}
</script>

<style scoped>

</style>