<template>
  <v-card rounded="lg" outlined class="my-2">
    <div>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="/images/avatars/avatar1.svg"/>
        </v-list-item-avatar>
        <v-list-item-content class="mt-2">
          <v-list-item-title class="questrial subtitle">
            <v-chip label small text-color="white" :color="getPriorityInfo(item.priority).color" class="pr-2" >
              {{ item.priorityLevelText }}
            </v-chip>
            {{ item.authorName }}
            <v-icon>mdi-menu-right</v-icon>
              {{ groupName }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs"
                        v-on="on" v-if="item.isSticky">mdi-sticker
                </v-icon>
              </template>
              <span>This is a sticky post.</span>
            </v-tooltip>
          </v-list-item-title>
          <v-list-item-subtitle class="questrial caption indigo--text">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >{{ getTimeFromNow(item.publishedDate) }}</span>
              </template>
              <span>Published on {{
                  item.publishedDate | moment('DD MMM YYYY')
                }} at {{ item.publishedDate | moment('HH:mm Z') }}</span>
            </v-tooltip>

          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div class="text-body-1 text--primary strong text-left">
            <post-action-menu :post-id="item._id" :is-author="item.isAuthor || isAnnouncementManager "
                              :has-read="item.hasOpened"
                              v-on:editPost="editPost(item)"
                              v-on:deletePost="deletePost(item)"/>
          </div>
        </v-list-item-action>
      </v-list-item>
    </div>
    <v-divider></v-divider>
    <v-card-text>
      <div>{{ item.title }}</div>
    </v-card-text>
    <v-card-text>
      <div class="questrial body1 mb-4" v-html="item.detail"/>
      <v-layout class="py-4">
        <v-flex>
          <div class="flex-grow-1">
            <div class="questrial mr-3 font-weight-bold" v-if="item.totalReactLike>0">
              <v-menu
                  open-on-hover
                  rounded="lg"
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      elevation="0"
                      x-small
                      fab
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-thumb-up-outline</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <div class="text-caption font-weight-bold">Like</div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                      v-for="(r, index) in item.reactions"
                      :key="index"
                  >
                    <v-list-item-content>
                      <div class="text-caption">{{ r.name }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
              {{ getReactionText(item.totalReactLike, item.iLike, userName) }}
            </div>
          </div>
        </v-flex>
        <v-flex class="flex-grow-1 text-right">
          <v-layout wrap justify-end>
            <div class="questrial mr-3 font-weight-bold ">
              {{ item.totalPosts > 0 ? `${item.totalPosts} Comments` : '' }}
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <post-interactions :priority-level="item.priority" :has-opened="item.hasOpened"
                         :has-acknowledged="item.hasAcknowledged" :posts-disabled="item.postsDisabled"
                         v-on:markAsRead="markAsRead(item)"
                         v-on:react="react(item)"
                         v-on:acknowledgeAnnouncement="acknowledgePost(item)"/>
      <v-divider></v-divider>
    </v-card-text>
    <div class="px-4" v-if="!item.postsDisabled">
      <v-text-field class="questrial" height="45px" background-color="grey lighten-3" append-icon=""
                    :disabled="item.postsDisabled"
                    placeholder="Write a comment..." rounded></v-text-field>
    </div>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import moment from "moment";
import PostInteractions from "@/components/shared/PostInteractions";
import PostActionMenu from "@/components/shared/PostActionMenu";
import {PostHttp} from "@/http/PostHttp";
import {getGroupName} from "@/util/helpers";

export default {
  name: "Post",
  components: {PostActionMenu, PostInteractions},
  props: {
    item: {
      type: Object
    },
    userId: {
      type: String
    },
    userName: {
      type: String
    },
    isAnnouncementManager: {
      type: Boolean,
      default: false
    },
    groups:{
      type:Array
    }
  },
  computed: {
    ...mapState("post", ['posts']),
    ...mapState("app", ["loggedInUser"]),
    ...mapState("person", ["persons"]),
    ...mapState("priorityLevels", ["priorityLevels"]),
    groupName(){
      return this.item? this.item.groupId ? this.getGroupName(this.item.groupId,'name'):'':''
    }
  },

  methods: {
    ...mapActions("post", ["setPosts","setActivePost","setManagePostDialog"]),
    ...mapActions("app", ["setToast", "setLoading", "setLoggedInUser"]),
    ...mapActions("person", ["setPersons"]),
    ...mapActions("user", ["setFlyingStatus"]),
    getGroupName(id, style) {
      return getGroupName(this.groups, id, style)
    },

    getReactionText(totalReactLike, iLike, name) {
      if (!iLike) return totalReactLike
      if (iLike && totalReactLike < 2) return name
      return `You and ${totalReactLike - 1} other ${(totalReactLike - 1) > 1 ? 's' : ''}`
    },

    getPriorityInfo(priorityLevel) {
      return this.priorityLevels.find(i => {
        return i.value == priorityLevel
      })
    },

    getTimeFromNow(publishedDate) {
      const time = moment(publishedDate)
      return time.fromNow()
    },

    async editPost(post) {
      await this.setActivePost(post)
      this.setManagePostDialog(true)
    },
    async deletePost(item) {
      if (confirm(`are you sure you want to delete the post?`)) {
        try {
          this.isLoading = true;
          await PostHttp.deletePost(item._id)
          await this.setPosts()
          this.setToast({color: 'success', text: 'Announcement deleted.', show: true});
        } catch (err) {
          if (err.response) {
            console.log(err.response.data)
          } else {
            console.log(err)
          }
        } finally {
          this.isLoading = false;
        }
      }
      return
    },

    async markAsRead(item) {
      // Update the Announcement to reflect that the user has opened the Announcement
      try {
        await this.setLoading(true)
        await PostHttp.setAsRead(item._id)
        await this.setPosts()
        await this.setFlyingStatus(this.loggedInUser._id)
        this.setToast({color: 'success', text: 'Announcement marked as read.', show: true});
      } catch (err) {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      } finally {
        this.setLoading(false)
      }
    },
    async acknowledgePost(item) {
      try {
        await this.setLoading(true)
        await PostHttp.setAsAcknowledged(item._id)
        await this.setPosts();
        await this.setFlyingStatus(this.loggedInUser._id)
        this.setToast({color: 'success', text: 'Announcement marked as acknowledged.', show: true});
      } catch (err) {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      } finally {
        this.setLoading(false)
      }
    },

    async react(item) {
      try {
        await this.setLoading(true)
        const person = {
          _id: this.userId,
          name: this.userName,
          reaction: "like"
        }
        await PostHttp.react(item._id, person)
        await this.setPosts()
      } catch (err) {
        if (err.response) {
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      } finally {
        this.setLoading(false)
      }
    },
  },
  data: () => ({
    items: [
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me'},
      {title: 'Click Me 2'},
    ],
  }),
}
</script>

<style scoped>

</style>