<template>
  <v-card rounded="lg" outlined class="my-2">
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="/images/avatars/avatar1.svg"/>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-text-field class="questrial" height="45px" background-color="grey lighten-3" append-icon=""
                      placeholder="Write a post..." rounded @keydown="open" @click="open"></v-text-field>
      </v-list-item-content>
    </v-list-item>
    <v-divider/>
    <v-dialog
        v-model="managePostDialog"
        persistent
        max-width="600px"
        v-if="activePost"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-list-item>
            <v-list-item-avatar v-if="showPrioritySelector || showAudienceSelector">
              <v-btn fab @click="showPrioritySelector=false;showAudienceSelector=false">
                <v-icon>mdi-arrow-left-circle</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <h5>{{
                  showPrioritySelector ? 'Select post priority level' : showAudienceSelector ? 'Select audience' : 'New Post'
                }}</h5>
            </v-list-item-content>
          </v-list-item>
        </v-card-title>
        <v-card-text>
          <v-divider/>
          <v-container>

            <v-row v-show="!showPrioritySelector && !showAudienceSelector">
              <v-col cols="12">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img src="/images/avatars/avatar1.svg"/>
                  </v-list-item-avatar>
                  <v-list-item-content three-line>
                    <div>{{ authorName }}</div>
                    <div v-if="activePost && authorGroups.length > 0">
                      <v-btn x-small elevation="0" text-color="white" color=""
                             @click="showAudienceSelector=true">
                        <v-icon left>mdi-earth</v-icon>
                        {{ getGroupName(activePost.groupId,'name') }}
                        <v-icon right>mdi-menu-down</v-icon>
                      </v-btn>
                    </div>
                    <div v-else class="text-caption">
                      <v-icon left>mdi-earth</v-icon>
                      {{ getGroupName(activePost.groupId,'name') }}
                    </div>
                    <div v-if="isAnnouncementManager && activePost">
                      <v-btn x-small elevation="0" text-color="white"
                             :color="getPriorityInfo(activePost.priority).color"
                             @click="showPrioritySelector=true" :disabled="!isAnnouncementManager">
                        {{ getPriorityInfo(activePost.priority).text }}
                        <v-icon right v-if="isAnnouncementManager">mdi-menu-down</v-icon>
                      </v-btn>
                    </div>
                    <div v-else>
                      <v-chip label x-small class="pr-2" elevation="0" text-color="white"
                              :color="getPriorityInfo(activePost.priority).color">
                        {{ getPriorityInfo(activePost.priority).text }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <div class="text-title">Message Detail</div>
                <vue-editor id="detail" v-model="activePost.detail"/>
              </v-col>
            </v-row>
            <v-row v-if="showPrioritySelector">
              <v-col cols="12">
                <div class="h6">How important is this post?</div>
                <div class="text-body-2">Your selection will let other people know how important your post is in
                  relation to the operation of the group
                </div>
              </v-col>
              <v-flex xs12 class="pa-2">
                <v-list three-line>
                  <v-radio-group v-model="activePost.priority">
                    <v-list-item v-for="(level,i) in priorityLevels" :key="i"
                                 @click="activePost.priority=level.value,showPrioritySelector=false">
                      <v-list-item-icon>
                        <div class="text-justify">
                          <v-btn x-small elevation="0" text-color="white" :color="level.color">
                            {{ level.text }}
                          </v-btn>
                        </div>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <div class="text-caption">Definition</div>
                        <div class="text-body-2">{{ level.definition }}</div>
                        <div class="text-caption font-weight-bold">
                          {{ level.requiresESignature ? 'Member will be required to sign off before next flight' : '' }}
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <div class="text-justify">
                          <v-radio
                              :value="level.value"
                          ></v-radio>
                        </div>
                      </v-list-item-action>
                    </v-list-item>
                  </v-radio-group>
                </v-list>
              </v-flex>
              <!--              <v-col cols="12">-->
              <!--                <div class="text-body-2"><strong>NB:</strong></div>-->
              <!--                <div class="text-body-2"><strong>When you publish your message it will remain on the Announcement page-->
              <!--                  until {{ activeAnnouncement.dateTo | moment('DD MMM YYYY') }} after which the announcement will expire-->
              <!--                  automatically.</strong></div>-->
              <!--              </v-col>-->
            </v-row>
            <v-row v-if="showAudienceSelector">
              <v-col cols="12">
                <div class="h6">Who can see your post?</div>
                <div class="text-body-2">Your post will appear in the announcement Feed of your chosen group(s)</div>
              </v-col>
              <v-flex xs12 class="pa-2">
                <v-list two-line-line>
                  <v-list-item v-for="(group,i) in authorGroups" :key="i" @click="audienceSelect(group)">
                    <v-list-item-avatar>
                      <v-icon>mdi-domain</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ getGroupName(group._id,'name') }}</v-list-item-title>
                      <v-list-item-subtitle>Only members of {{ getGroupName(group._id,'name') }} will see this post.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-flex>
            </v-row>
          </v-container>
          <v-divider/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <app-btn
              color="secondary darken-1"
              @click="cancel"
          >
            Cancel
          </app-btn>
          <app-btn
              color="primary darken-1"
              @click="save"
              :disabled="!formValid"
          >
            Post
          </app-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import {PostHttp} from "@/http/PostHttp";
import { VueEditor } from "vue2-editor";
import {getGroupName} from "@/util/helpers";

export default {
  name: "PostModel",
  components: {AppBtn, VueEditor},
  props: {
    authorName: {
      type: String
    },
    isAnnouncementManager: {
      type: Boolean,
      default: false
    },
    authorGroups: {
      type: Array,
      required: true,
    },
    // authorId: {
    //   type: String
    // },
    activeGroup: {
      type: Object,
      required: true
    },
    groups:{
      type: Array
    }
  },
  data() {
    return {
      showPrioritySelector: false,
      showAudienceSelector: false,
      editorConfig: {
        // The configuration of the editor.
        colorButton_colors: "primary"
      }
      // title: null,
      // priorityLevel: 3,
      // detail: null,
    }
  },
  computed: {
    ...mapState("app", ["loading"]),
    ...mapState("post", ["managePostDialog", 'activePost']),
    ...mapState("priorityLevels", ["priorityLevels"]),
    formValid() {
      return this.activePost.detail && this.activePost.detail.length > 20 && this.activePost.priority
    }
  },
  methods: {
    ...mapActions("app", ["setToast"]),
    ...mapActions("post", ["setManagePostDialog", "setActivePost", "setPosts"]),
    getGroupName(id, style) {
      return getGroupName(this.groups, id, style)
    },
    open() {
      this.$emit('newPost')
    },
    cancel() {
      this.setManagePostDialog(false)
    },
    audienceSelect(audience) {
      this.activePost.groupId = audience._id
      this.activePost.groupName = audience.name
      this.showAudienceSelector = false
      this.showPrioritySelector = false
    },
    async save() {
      this.isLoading = true;
      this.activePost.isPublished = true
      try {

        if (this.activePost.isNewPost) {
          delete this.activePost.isNewPost
          await PostHttp.createNewPost(this.activePost)
        } else {
          await PostHttp.updatePost(this.activePost)
        }
        this.setToast({color: 'success', text: 'New Post created.', show: true});
        this.$emit('refreshPosts')
        // this.isReadOnly = true

      } catch (err) {
        if (err.response) {
          this.setToast({color: 'error', text: err.response.data.message, show: true});
          console.log(err.response.data)
        } else {
          console.log(err)
        }
      } finally {
        // this.detail = null
        // this.priorityLevel = 3,
        //     this.isLoading = false;
        this.cancel()
      }
    },
    getPriorityInfo(priorityLevel) {
      return this.priorityLevels.find(i => {
        return i.value == priorityLevel
      })
    },


  }
}
</script>

<style>

</style>