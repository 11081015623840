<template>
  <v-layout>
    <v-flex>
      <div class="flex-grow-1">
        <v-btn class="flex-grow-1"
               block
               height="48"
               text
               color="primary"
               @click="emitAction('react')">
          <v-icon class="pr-2">mdi-thumb-up-outline</v-icon>
          <div v-if="!$vuetify.breakpoint.mobile">Like</div>
        </v-btn>
      </div>
    </v-flex>
    <v-flex>
      <div class="flex-grow-1">
        <v-btn class="flex-grow-1"
               block
               height="48"
               text
               color="primary"
               :disabled="postsDisabled"
               @click="emitAction('toggleComments')">
<!--          <v-icon class="pr-2">mdi-comment-outline</v-icon>-->
<!--          <div v-if="!$vuetify.breakpoint.mobile">Comment</div>-->
        </v-btn>
      </div>
    </v-flex>
    <v-flex>
      <div class="flex-grow-1">
        <v-btn class="flex-grow-1"
               block
               height="48"
               text
               color="primary"
               :disabled="scopedAction.disabled"
               @click="emitAction(scopedAction.action)">
          <post-action-status-icon :has-acknowledged="hasAcknowledged" :has-opened="hasOpened"
                                         :priority-level="priorityLevel"/>
          <div v-if="!$vuetify.breakpoint.mobile">{{ scopedAction.text }}</div>
        </v-btn>
      </div>
    </v-flex>
    <!--      <v-flex v-for="(action,index) in availableActions" :key="index">-->
    <!--        <div class="flex-grow-1">-->
    <!--          <v-tooltip bottom>-->
    <!--            <template v-slot:activator="{ on, attrs }">-->
    <!--              <v-btn class="flex-grow-1"-->
    <!--                     block-->
    <!--                     height="48"-->
    <!--                     text :color="action.color"-->
    <!--                     v-bind="attrs"-->
    <!--                     v-on="on"-->
    <!--                     :disabled="isDisabled(action)"-->
    <!--                     :loading="false"-->
    <!--                     @click="emitAction(action.action)">-->
    <!--                <v-icon class="pr-2">{{ action.icon }}</v-icon>-->
    <!--                {{ action.text }}-->
    <!--              </v-btn>-->
    <!--            </template>-->
    <!--            <span>{{ action.toolTip }}</span>-->
    <!--          </v-tooltip>-->
    <!--        </div>-->
    <!--      </v-flex>-->
  </v-layout>
</template>

<script>
import PostActionStatusIcon from "@/components/shared/PostActionStatusIcon";

export default {
  name: "PostInteractions",
  components: {PostActionStatusIcon},
  props: {
    priorityLevel: {
      type: Number
    },
    hasOpened: {
      type: Boolean
    },
    hasAcknowledged: {
      type: Boolean
    },
    isAuthor: {
      type: Boolean,
    },
    postsDisabled: {
      type: Boolean,
    }
  },
  data() {
    return {
      noFurtherActionRequired: {
        icon: 'mdi-check-circle-outline',
        color: 'primary',
        text: 'No action required',
        toolTip: '',
        action: '',
        appliesPriorityLevels: [1, 2],
        postAuthorOnly: false,
        hasOpened: true,
        hasAcknowledged: true,
        disabled: true
      },
      availableActions: [
        {
          icon: 'mdi-email-open',
          color: 'primary',
          text: 'Mark as Read',
          toolTip: 'Mark this announcement as Read',
          action: 'markAsRead',
          appliesPriorityLevels: [1, 2, 3],
          hasOpened: false,
          hasAcknowledged: false,
          disabled: false
        },
        {
          icon: 'mdi-fountain-pen-tip',
          color: 'primary',
          text: 'electronically sign',
          toolTip: 'Electronically sign',
          action: 'acknowledgeAnnouncement',
          appliesPriorityLevels: [1, 2],
          postAuthorOnly: false,
          hasOpened: true,
          hasAcknowledged: false,
          disabled: false
        },
      ],
    }
  },
  computed: {
    scopedAction() {
      const action = this.availableActions.find(i => i.hasOpened === this.hasOpened && i.hasAcknowledged === this.hasAcknowledged && i.appliesPriorityLevels.includes(this.priorityLevel))

      return action ? action : this.noFurtherActionRequired
    }
  },
  methods: {
    emitAction(action) {
      this.$emit(action)
    }
  }
}
</script>

<style scoped>

</style>