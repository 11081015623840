<template>
  <v-tooltip left v-if="selectedIconData">
    <template v-slot:activator="{ on, attrs }">
      <v-icon icon v-bind="attrs"
              v-on="on"
              :color="selectedIconData.color">{{ selectedIconData.icon }}
      </v-icon>
    </template>
    <span>{{ selectedIconData.toolTip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "PostActionStatusIcon",
  props: {
    priorityLevel: {
      type: Number
    },
    hasOpened: {
      type: Boolean
    },
    hasAcknowledged: {
      type: Boolean
    },
  },
  data() {
    return {
      availableIcons: [
        {
          icon: 'mdi-email',
          color: 'red',
          name: 'Unread',
          appliesPriorityLevels: [1, 2],
          hasOpened: false,
          hasAcknowledged: false,
          toolTip: 'This announcement hasn\'t been read.'
        },
        {
          icon: 'mdi-email',
          color: 'orange',
          name: 'Unread',
          appliesPriorityLevels: [3],
          hasOpened: false,
          hasAcknowledged: false,
          toolTip: 'This announcement hasn\'t been read.'
        },
        {
          icon: 'mdi-email-open',
          color: 'green',
          appliesPriorityLevels: [3],
          hasOpened: true,
          hasAcknowledged: false,
          toolTip: 'This announcement has been read. No further action is required.'
        },
        {
          icon: 'mdi-email-open',
          color: 'red',
          name: 'Unread',
          appliesPriorityLevels: [1, 2],
          hasOpened: true,
          hasAcknowledged: false,
          toolTip: 'This announcement has been read but you are yet to provide an electronic signature.'
        },
        {
          icon: 'mdi-signature-freehand',
          color: 'green',
          name: 'Unread',
          appliesPriorityLevels: [1, 2],
          hasOpened: true,
          hasAcknowledged: true,
          toolTip: 'Electronic signature has been provided. No further action required.'
        }

      ]
    }
  },
  computed: {
    selectedIconData() {
      const iconData = this.availableIcons.find(i => i.hasOpened === this.hasOpened && i.hasAcknowledged === this.hasAcknowledged && i.appliesPriorityLevels.includes(this.priorityLevel))
      return iconData ? iconData : null
    }
  }

}
</script>

<style scoped>

</style>