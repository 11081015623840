<template>
  <v-card class="h-full d-flex flex-column">
    <div class="pa-3 flex-grow-1">
      <div class="d-flex align-center flex-column">
        <v-avatar small size="120">
          <v-img v-if="image.length>0" :src="image" :alt="name"/>
          <v-icon v-else size="120">mdi-account</v-icon>
        </v-avatar>
        <div class="text-center mt-2">
          <span class="font-weight-bold text-body-2">{{ name }}</span>
          <div class="secondary--text text--lighten-1 text-caption">
            {{ title }}
          </div>
        </div>
        <v-chip v-if="licence" small color="accent" class="font-weight-bold mt-2">{{ licence }}</v-chip>
        <v-chip small color="accent" class="font-weight-bold mt-2" v-for="(certificate,i) in certificates" :key="i">
          {{ certificate.name }}
        </v-chip>
        <v-chip v-if="role" small color="accent" class="font-weight-bold mt-2">{{ role }}</v-chip>
        <div class="secondary--text text--lighten-1 text-caption">
          {{ email }}
        </div>
        <div class="secondary--text text--lighten-1 text-caption">
          {{ phone }}
        </div>
      </div>
    </div>

    <v-divider></v-divider>

    <div class="d-flex">
      <v-btn
          v-if="phone"
          class="flex-grow-1"
          tile
          height="48"
          text
          :href="`tel:${phone}`"
      >
        <v-icon left>mdi-phone-outline</v-icon>
        Call
      </v-btn>
      <v-divider v-if="email && phone" vertical></v-divider>


        <v-btn
            class="flex-grow-1"
            tile
            height="48"
            text
            @click="$router.push(`/p/${id}`)"
        >
          <v-icon left>mdi-account</v-icon>
          Profile
        </v-btn>
<!--      <router-link :to="`/p/${id}`">-->
<!--      </router-link>-->
      <v-divider vertical />
      <v-btn
          v-if="email"
          class="flex-grow-1"
          tile
          height="48"
          text
          :href="`mailto:${email}`" target="_blank"
      >
        <v-icon left>mdi-email-outline</v-icon>
        Email
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "MemberCard",
  props: {
    id: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    licence: {
      type: String,
      default: ''
    },
    certificates: {},
    title: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    }
  }
}
</script>


<style scoped>

</style>